import {useNavigate,} from "react-router-dom";
import {Button, Drawer, Dropdown, message, Row, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCog, faSignOutAlt, faTachometerAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";

const HeaderLayout = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()
    const [menuDrawerModal, setMenuDrawerModal] = useState(false);
    const {logout} = useContext(AuthContext);

    const  clearCookiesAndReload = () => {
        // Tüm çerezleri sil
        message.success("Uygulama güncelleniyor. Lütfen bekleyin.");

        document.cookie.split(";").forEach((cookie) => {
            document.cookie = cookie
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/");
        });

        // Sayfayı yeniden yükle
        window.location.reload();
    }

    const drawerMenu = (
        <Space direction={"vertical"} style={{ width: '100%' }}>
            <Button block icon={<FontAwesomeIcon icon={faTachometerAlt} />} onClick={() => {navigate("/dashboard");setMenuDrawerModal(false)}} style={{ textAlign: 'left' }}>
                Ana Sayfa
            </Button>

            <h3 style={{ textAlign: 'left', margin: '10px 0' }}>Raporlar</h3>

            <Button block  onClick={() => {navigate("/report/kasa-gunluk-rapor");setMenuDrawerModal(false)}} style={{ textAlign: 'left' }}>
                Günlük Kasa Raporu
            </Button>

            <Button block  onClick={() => {navigate("/report/kasa-tarih-rapor");setMenuDrawerModal(false)}} style={{ textAlign: 'left' }}>
                Tarih Bazlı Kasa Raporu
            </Button>

            <Button block  onClick={() => {navigate("/report/kasa-aylik-dokum");setMenuDrawerModal(false)}} style={{ textAlign: 'left' }}>
                Aylık Döküm Raporu
            </Button>




            <h3 className={"mt-2"}>Kullanıcı İşlemleri</h3>
            <Button block icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={() => logout()} style={{ textAlign: 'left' }}>
                Çıkış
            </Button>

            <h3 className={"mt-2"}>Güncelleme</h3>
            <Button block icon={<FontAwesomeIcon icon={faCog} />} onClick={() => clearCookiesAndReload()} style={{ textAlign: 'left' }}>
                Uygulama Güncelle
            </Button>
        </Space>
    )

    return (
        <>
            <Drawer
                open={menuDrawerModal}
                onClose={() => setMenuDrawerModal(false)}
                placement="left"
                width={"250px"}
            >
                <Row>
                    {drawerMenu}

                </Row>
            </Drawer>
            <div className={"header"}>
                <div onClick={() => navigate("/dashboard")} className={"logo"}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
                         alt={"Firma Logosu"}></img>
                    <div className={"logo-text"}>| Boss App</div>
                </div>
                <div className={"right"}>
                    <Button onClick={() => setMenuDrawerModal(true)} icon={<FontAwesomeIcon icon={faBars}/>}></Button>
                </div>
            </div>
        </>

    )
}

export default HeaderLayout;