import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import AuthLayout from "./layout/AuthLayout";
import LoginPage from "./pages/auth/LoginPage";
import ProtectedLayout from "./layout/ProtectedLayout";
import DashboardPage from "./pages/home/DashboardPage";
import "../src/styles/Style.sass"
import KasaGunlukRaporPage from "./pages/report/KasaGunlukRaporPage";
import KasaTarihBazliRaporPage from "./pages/report/KasaTarihBazliRaporPage";
import KasaAylikDokumRaporPage from "./pages/report/KasaAylikDokumRaporPage";

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem("user");
            resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >
            <Route>
                <Route path={"/login"} element={<LoginPage></LoginPage>}></Route>
            </Route>

            <Route path="/" element={<ProtectedLayout />}>
                <Route path="/" element={<DashboardPage />} />
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>

                <Route path="/dashboard" element={<DashboardPage />} />
            </Route>


            <Route path="/report" element={<ProtectedLayout />}>
                <Route path="/report/kasa-gunluk-rapor" element={<KasaGunlukRaporPage />} />
                <Route path="/report/kasa-tarih-rapor" element={<KasaTarihBazliRaporPage />} />
                <Route path="/report/kasa-aylik-dokum" element={<KasaAylikDokumRaporPage />} />

            </Route>

        </Route>
    )
)
