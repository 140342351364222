import {createContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import TokenService from "../services/TokenService";

const AuthContext = createContext();

const AuthContextProvider = ({children, userData}) => {
    const [user, setUser] = useState({userData});
    const navigate = useNavigate();

    const login = (user) =>{
        setUser(user);
        navigate("/dashboard", {replace: false});
        TokenService.setUser(user)
    }

    const logout = () => {
        setUser(null);
        navigate("/login", {replace: false});
        TokenService.removeUser()
    }

    return(
        <AuthContext.Provider
            value={{
                user,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContextProvider, AuthContext}
