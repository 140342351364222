import {Navigate, useOutlet} from "react-router-dom";
import HeaderLayout from "./HeaderLayout";


const ProtectedLayout = () => {
    const outlet = useOutlet();
    const user = window.localStorage.getItem("user");

    if(outlet == null){
        return(<div>Görüntülenecek Sayfa Yok</div>)
    }

    if (!user ) {
        return <Navigate to="/login"/>;
    }

    return(
        <div className={"app"}>
            <HeaderLayout></HeaderLayout>
            <div className={"app-content"}>
                {outlet}
            </div>
            <div className={"footer"}></div>
        </div>
    )
}

export default ProtectedLayout