import {Badge, Card, Col, List, message, Row} from "antd";
import flexCurrency from "../../utils/FlexCurrency";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import Api from "../../services/Api";

const DashboardPage = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
    },[])

    const getData = () => {
       Api.get("/api/Report/GetBosAppDailyReport?RaporTarihi="+dayjs().format("YYYY-MM-DD")).then(res => {
              setData(res.data)
       }).catch(err => {
              console.log(err)
       })
    }

    //15 saniye de bir yenilesin
    useEffect(() => {
        const interval = setInterval(() => {
            getData()
            message.success("Veriler Otomatik Güncellendi")
        }, 15000);
        return () => clearInterval(interval);
    }, []);



    return(
        <div className={"p-1"}>
           <Row gutter={[10,10]}>
               <Col span={24}>
                   <Card>
                       <div className={"d-flex justify-content-between"}>
                           <h2>Hoşgeldiniz</h2>
                           <h2>{dayjs().format("DD MMMM YYYY")}</h2>
                       </div>

                   </Card>
               </Col>
               <Col span={24}>
                   <Badge.Ribbon text="CANLI" color={"red"}>
                       <Card>
                           <Row gutter={[10,10]}>
                               <Col span={8}>
                                   <img width={60} src={process.env.PUBLIC_URL + '/assets/images/turnike.png'} alt={"Firma Logosu"}></img>
                               </Col>

                               <Col span={16}>
                                   <Row>
                                       <Col span={24}>
                                           <div style={{fontWeight:"bold", textAlign:"right"}}>
                                               BUGÜN TOPLAM ZİYARETÇİ SAYISI
                                           </div>
                                       </Col>
                                       <Col span={24}>
                                           <div className={"font-color-green"} style={{fontWeight:"bold", textAlign:"right",fontSize:50}}>
                                               {data?.toplamZiyaretciSayisi}
                                           </div>
                                       </Col>
                                   </Row>
                               </Col>
                           </Row>
                       </Card>
                   </Badge.Ribbon>

               </Col>

               <Col span={24}>
                   <Badge.Ribbon text="CANLI" color={"red"}>
                       <Card>
                           <Row gutter={[10,10]}>
                               <Col span={8} style={{alignContent:"center"}}>
                                   <img width={75} src={process.env.PUBLIC_URL + '/assets/images/winner.png'} alt={"Firma Logosu"}></img>
                               </Col>

                               <Col span={16}>
                                   <Row className={"h-100"}>
                                       <Col span={24}>
                                           <div style={{fontWeight:"bold", textAlign:"right"}}>
                                               BUGÜN İÇİN TOPLAM TUTAR
                                           </div>
                                       </Col>
                                       <Col span={24}>
                                           <div className={"font-color-green"} style={{fontWeight:"bold", textAlign:"right",fontSize:30, justifyContent:"center", alignContent:"center"}}>
                                               {flexCurrency(data?.toplamKazanilanTutar)} TL
                                           </div>
                                       </Col>
                                   </Row>
                               </Col>
                           </Row>
                       </Card>
                   </Badge.Ribbon>

               </Col>

               <Col span={24}>
                   <Card title={"Satış Tip Tutarları"}>
                        {/*burada hem kredi kartı hemde nakit ne kadar onu belirteceğim*/}
                        <Row>
                            <Col span={12}>
                                <Card className={"h-100"}>
                                    <Row>
                                        <Col span={24}>

                                            <div style={{fontWeight:"bold", textAlign:"center"}}>
                                                <div>
                                                    <img width={50}
                                                         src={process.env.PUBLIC_URL + '/assets/images/kasa.png'}
                                                         alt={""}></img>
                                                </div>
                                                <div>
                                                    NAKİT KASA
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div style={{fontWeight:"bold", textAlign:"center"}}>
                                                {flexCurrency(data?.toplamNakitKazanilanTutar)} TL
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={12}>
                                <Card className={"h-100"}>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{fontWeight: "bold", textAlign: "center"}}>
                                                <img width={50}
                                                     src={process.env.PUBLIC_URL + '/assets/images/kredi-karti.png'}
                                                     alt={""}></img>

                                                <div>
                                                    KREDİ KARTI
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                        <div style={{fontWeight:"bold", textAlign: "center"}}>
                                            {flexCurrency(data?.toplamKrediKartiKazanilanTutar)} TL
                                        </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                   </Card>
               </Col>


               <Col span={24}>
                   <Card title={"Tarife Satışları"}>
                       <List
                           size="small"
                           bordered
                           dataSource={data?.toplamTarifeSatislari}
                           renderItem={(item) => <List.Item>
                               <Row gutter={[10,10]} style={{width:"100%"}}>
                                   <Col span={8} className={"font-color-green font-weight-bold"}>
                                       {item.tarifeAdi}
                                   </Col>
                                   <Col span={8}>
                                       <div className={"font-weight-bold w-100 text-align-center"}>{item.satisSayisi} ADET</div>
                                   </Col>
                                   <Col span={8}>
                                       <div className={"font-weight-bold w-100 text-align-right"}>{flexCurrency(item.kazanilanTutar)} TL</div>
                                   </Col>
                               </Row>
                           </List.Item>}
                       />
                   </Card>


               </Col>

               <Col span={24} style={{marginBottom:50}}>

               </Col>
           </Row>
        </div>
    )
}

export default DashboardPage