import {useRef, useState} from "react";
import {Button, Card, Col, DatePicker, Descriptions, Form, message, Row, Table} from "antd";
import Column from "antd/es/table/Column";
import Api from "../../services/Api";
import dayjs from "dayjs";
import flexCurrency from "../../utils/FlexCurrency";

const KasaTarihBazliRaporPage = () => {
    const [data, setData] = useState({gunlukRaporList: []});
    const [form] = Form.useForm();

    const printRef = useRef(null)


    const getData = (values) => {
        Api.get("/api/Report/GetBuyingReport?StartReportDate="+dayjs(values.startReportDate).format("YYYY-MM-DD")+"&EndReportDate="+dayjs(values.endReportDate).format("YYYY-MM-DD")).then(res => {
            setData(res.data)
        }).catch(err => {
            message.error(err.response.data.message)
        })
    }

    const onFinisFailed = () => {
        message.error("Bir şeyler ters gitti. Lütfen tekrar deneyin.")
    }

    return(<div className={"p-1"}>
        <Card title={"Rapor Sorgula"} >
            <Form
                form={form}
                onFinish={getData}
                onFinishFailed={onFinisFailed}
                layout={"inline"}
                labelAlign={"left"}
                labelCol={{
                    span: 10,
                }}
                colon={false}
            >
                <Form.Item
                    label={"Başlangıç Tarihi"} style={{width:350}} name={"startReportDate"} rules={[{ required: true },]}>
                    <DatePicker  class={'w-100'}  format={"DD.MM.YYYY"}></DatePicker>
                </Form.Item>

                <Form.Item
                    label={"Bitiş Tarihi"} style={{width:350}} name={"endReportDate"} rules={[{ required: true },]}>
                    <DatePicker  class={'w-100'}  format={"DD.MM.YYYY"}></DatePicker>
                </Form.Item>

                <Form.Item>
                    <Button type={"primary"} htmlType="submit" className={"w-100"}>Raporu Getir</Button>
                </Form.Item>

            </Form>

        </Card>

        <Card title={"Gün Sonu Detaylı Rapor"} ref={printRef} className={"mt-1"}>
            <Row>
                <Col span={24}>
                    <Descriptions.Item >
                        <Col span={24}>
                            <Descriptions size={"small"} bordered={true} >
                                <Descriptions.Item span={3} label={"Rapor Adı"}>Turnike Sistemi Tarih Arası Rapor</Descriptions.Item>
                                <Descriptions.Item span={3} label={"Başlangıç Rapor Tarihi"}>{data?.startReportDate}</Descriptions.Item>
                                <Descriptions.Item span={3} label={"Bitiş Rapor Tarihi"}>{data?.endReportDate}</Descriptions.Item>
                                <Descriptions.Item span={3} label={"Rapor Yazdırma Tarihi"}>{data?.reportPrintDate}</Descriptions.Item>
                                <Descriptions.Item span={3} label={"Toplam Tutar"}><div className={"font-weight-bold"}>{flexCurrency(data.total)} TL</div></Descriptions.Item>
                                <Descriptions.Item span={3} label={"Rapor Alan Personel"}>Sistem</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Descriptions.Item>
                </Col>
            </Row>
        </Card>

        <Card className={"mt-1"}>
            <Col span={24}>
                {data.reportList?.length > 0 ?
                    <Table dataSource={data.reportList} pagination={false}  >
                        <Column title={"Tarife Adı"} align={"left"} width={120} dataIndex={"recipeName"}></Column>
                        <Column title={`Geçiş Sayısı (${data.reportList.reduce((total, item) => total + item.count, 0)} Kişi)`} align={"center"} width={120} dataIndex={"count"} render={sayi => (sayi + " kişi")}></Column>
                        <Column className={"font-weight-bold"} title={`Geçiş Toplam Tutarı (${flexCurrency(data.reportList.reduce((total, item) => total + item.recipeRowTotalPrice, 0))} TL)`} align={"right"} width={120} dataIndex={"recipeRowTotalPrice"} render={toplam => (flexCurrency(toplam) + " TL")}></Column>
                    </Table>: <p>Rapor Bulunamadı</p>
                }
            </Col>
        </Card>
    </div>)
}

export default KasaTarihBazliRaporPage